import React, { useMemo, useState } from "react";
import { Table } from "antd";
import { _t } from "../../../Common/components/InjectIntlContext";
import { usePaginator, useSubtotals } from "../../hooks";
import { NoData } from "../../../Common/components/NoData";
import { RenderSubtotal } from "./RenderSubtotal";
import { SearchOutlined } from "@ant-design/icons";
import { affiliateReportConfig } from "../../constants";
import AntFilter from "../../../Common/components/AntFilter";

export function ReportTable({
  pageSize,
  columns,
  data,
  isLoading,
  total,
  tableSize = null,
  setFilters,
  userStatuses,
}) {
  const [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = usePaginator();

  const onChangeReport = (pagination, filters, sorter, extra) => {
    if (sorter && sorter.field) {
      const config = affiliateReportConfig({});
      setFilters({
        sort:
          (config[sorter.field]
            ? config[sorter.field].searchField
            : sorter.field) +
          ":" +
          (sorter.order === "descend" ? "desc" : "asc"),
      });
    } else {
      setFilters({ sort: "" });
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
    confirm();
    setSearchFilter(dataIndex);
    setFilters({
      [searchProp]: selectedKeys.join(","),
    });
  };

  const handleReset = (clearFilters, searchProp) => {
    clearFilters();
    setFilters({
      [searchProp]: "",
    });
  };

  const getColSearchProps = (dataIndex, params) => ({
    filterDropdown: (filterProps) => (
      <AntFilter
        {...filterProps}
        dataIndex={dataIndex}
        setFilters={setFilters}
        searchProp={params.searchField}
        params={params.options({
          ...filterProps,
          handleSearch,
          dataIndex,
          searchProp: params.searchField,
        })}
        handleSearch={handleSearch}
        handleReset={handleReset}
        setSearchFilter={setSearchFilter}
        Component={params.component}
      />
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (text) => (searchFilter === dataIndex ? text.toString() : text),
    sorter: params.sorter,
  });

  const translatedColumns = useMemo(() => {
    const config = userStatuses?.statuses
      ? affiliateReportConfig({
          userStatuses: userStatuses.statuses,
          userRoles: userStatuses.roles,
          trafficTypes: userStatuses.traffic_types,
          cashTypes: userStatuses.cash_types,
        })
      : {};
    return columns.map((c) => {
      const addConfig = config[c.dataIndex]
        ? {
            ...getColSearchProps(c.dataIndex, config[c.dataIndex]),
          }
        : {};
      return {
        ...addConfig,
        ...c,
        title: _t(c.title),
      };
    });
  }, [columns, userStatuses]);
  const subTotals = useSubtotals(data, translatedColumns);

  return (
    <Table
      loading={isLoading}
      columns={translatedColumns}
      dataSource={data}
      rowKey={(r, i) => i}
      pagination={{
        total: total,
        pageSize: pageSize,
        hideOnSinglePage: true,
        onChange: setPage,
        current: page,
        showSizeChanger: false,
        position: ["bottomCenter"],
      }}
      locale={{ emptyText: <NoData /> }}
      summary={() => {
        return (
          <RenderSubtotal
            subTotals={subTotals}
            translatedColumns={translatedColumns}
          />
        );
      }}
      className={"report-table"}
      size={tableSize}
      onChange={onChangeReport}
    />
  );
}
