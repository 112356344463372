import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./helpers/PrivateRoute";
import {
  LoginByHash,
  ForgotPassword,
  Registration,
  UserActivation,
  ResetPassword,
} from "../modules/Auth/Pages";
import User from "../modules/User/components";
import Dashboard from "../modules/Dashboard/components/index.tsx";
import Promo from "../modules/Promo/components/index";
import AllComponents from "../modules/Common/AllComponents";
import Trackers from "../modules/Trackers/pages/Trackers";
import Payments from "../modules/Payments/pages/Payments";
import Affiliates from "../modules/Affiliates/pages/Affiliates";
import Costs from "../modules/Costs/components";
import Commission from "../modules/Landing/pages/commission";
import Faq from "../modules/Faq/pages/Faq";
import LandingMain from "../modules/Landing/pages/index";
import CommissionRevshare from "../modules/Landing/pages/commissions/commissionRevshare";
import CommissionCpa from "../modules/Landing/pages/commissions/commissionCpa";
import CommissionSubAffiliate from "../modules/Landing/pages/commissions/commissionSubAffiliate";
import ContactsPage from "../modules/Contacts/pages/ContactsPage";
import UsersAdmin from "../modules/Users/pages/UsersAdmin";
import { LoginById } from "../modules/Auth/Pages/LoginById";
import Login from "../modules/Auth/Pages/Login";
import { UsersProfile } from "../modules/Users/pages/UsersProfile";
import { APP_ROUTES } from "./constants/constantsUrl";
import Terms from "../modules/Landing/pages/Terms";
import PostbackOptions from "../modules/Landing/pages/PostbackOptions";
import Testimonials from "../modules/Testimonials/pages/Testimonials";
import TranslationsAdmin from "../modules/Translations/pages/TranslationsAdmin";
import TranslationsEdit from "../modules/Translations/pages/TranslationsEdit";
import NewsList from "../modules/News/pages/NewsList";
import { NewsContent } from "../modules/News/pages/NewsContent";
import UsersCreate from "../modules/Users/pages/UsersCreate";
import Managers from "../modules/Managers/pages/Managers";
import AffiliatesReport from "../modules/Reports/pages/AffiliatesReport";
import { Page404 } from "../pages/error";
import {
  AffiliateBrandItemEdit,
  AffiliateBrands,
  AffiliateLandingCreate,
  AffiliateLandingEdit,
  AffiliateProgramCreate,
  AffiliateProgramEdit,
  ChatCreateForm,
  ChatInnerPage,
  ChatsListAdmin,
  ChatsListUser,
  ChatCreateFormAdmin,
  ChatInnerPageAdmin,
} from "../pages/inner";
import { permissions } from "./constants/roles";

export default (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/login-by-hash" component={LoginByHash} />
    <Route path={APP_ROUTES.user.restore_password} component={ForgotPassword} />
    <Route path={APP_ROUTES.user.reset_password} component={ResetPassword} />
    <Route path={APP_ROUTES.registration} component={Registration} />
    <Route path="/registration-activate" component={UserActivation} />
    <Route path="/commission/revshare" component={CommissionRevshare} />
    <Route path="/commission/cpa" component={CommissionCpa} />
    <Route path="/commission/subaffiliate" component={CommissionSubAffiliate} />
    <Route path="/commission" component={Commission} />
    <Route path="/faq" component={Faq} />
    <Route path="/contacts" component={ContactsPage} />
    <Route path={APP_ROUTES.terms} component={Terms} />
    <Route path={APP_ROUTES.postback_options} component={PostbackOptions} />
    <Route path={APP_ROUTES.testimonials} component={Testimonials} />
    <Route path={APP_ROUTES.news.list} component={NewsList} />
    <Route path={APP_ROUTES.news.show} component={NewsContent} />
    {/*<Route path={APP_ROUTES.tournaments.list} component={TournamentsList} />*/}
    {/*<Route path={APP_ROUTES.tournaments.show} component={TournamentShow} />*/}
    <Route exact path="/" component={LandingMain} />
    <PrivateRoute path="/dashboard" component={Dashboard} />
    <PrivateRoute path="/promo" component={Promo} />
    <PrivateRoute path="/trackers" component={Trackers} />
    <PrivateRoute path="/payments" component={Payments} />
    <PrivateRoute path="/affiliates" component={Affiliates} />
    <PrivateRoute path="/costs" component={Costs} />
    <PrivateRoute path="/user" component={User} />
    <PrivateRoute path="/all-components" component={AllComponents} />
    <PrivateRoute exact path={APP_ROUTES.manage.users} component={UsersAdmin} />
    <PrivateRoute path={APP_ROUTES.manage.managers} component={Managers} />
    <PrivateRoute
      path={APP_ROUTES.reports.affiliates_report}
      component={AffiliatesReport}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.users_create}
      component={UsersCreate}
    />
    <PrivateRoute
      path={APP_ROUTES.manage.user_profile}
      component={UsersProfile}
    />
    <PrivateRoute path="/login-by-id" component={LoginById} />
    <PrivateRoute
      path={APP_ROUTES.content.translations.list}
      component={TranslationsAdmin}
    />
    <PrivateRoute
      path={APP_ROUTES.content.translations.show}
      component={TranslationsEdit}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.brands}
      exact
      component={AffiliateBrands}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.brand_edit}
      exact
      component={AffiliateBrandItemEdit}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.landing_edit}
      exact
      component={AffiliateLandingEdit}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.landing_create}
      exact
      component={AffiliateLandingCreate}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.program_edit}
      exact
      component={AffiliateProgramEdit}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.program_create}
      exact
      component={AffiliateProgramCreate}
    />
    <PrivateRoute
      path={APP_ROUTES.chat.inner}
      exact
      component={ChatInnerPage}
    />
    <PrivateRoute
      path={APP_ROUTES.chat.create}
      exact
      component={ChatCreateForm}
    />
    <PrivateRoute path={APP_ROUTES.chat.list} exact component={ChatsListUser} />
    <PrivateRoute
      path={APP_ROUTES.chat.admin.inner}
      exact
      component={ChatInnerPageAdmin}
      permissions={permissions.chat.admin}
    />

    <PrivateRoute
      path={APP_ROUTES.chat.admin.list}
      exact
      component={ChatsListAdmin}
      permissions={permissions.chat.admin}
    />
    <PrivateRoute
      path={APP_ROUTES.chat.admin.create}
      exact
      component={ChatCreateFormAdmin}
      permissions={permissions.chat.admin}
    />
    <Route component={Page404} />
  </Switch>
);
