import { getPostback } from "./api";
import { useQuery } from "react-query";
import { getUserId } from "../../system/helpers/localstorageHelper";
import { removeEmpty } from "../Dashboard/apiHooks";

const PostBack = "postback";

export const useGetPostback = (params) => {
  return useQuery(
    [`${PostBack}-${params.currentPage}`, params],
    (k, params) => {
      const requestParams = {
        date_from: params.date.from,
        date_to: params.date.to,
        game: params.game,
        page_size: params.page_size,
        refcode: params.refcode,
        url: params.url,
        page: params.page,
      };
      return getPostback(
        {
          user_id: getUserId(),
          ...removeEmpty(requestParams),
        } as any,
        params.currentPage
      );
    }
  );
};
