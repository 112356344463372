import React from "react";
import {FaqResponse} from "../../api";
import {Faqs} from "../Faqs/Faqs";
import {FaqTitle} from "../FaqTitle/FaqTitle";
import {_t} from "../../../Common/components/InjectIntlContext";

type FaqTitlesProps = {
  data: FaqResponse;
  loading: boolean;
  setFilter: (v) => void
}

export function FaqTitles({data, loading, setFilter}: FaqTitlesProps) {
  const tiles = (_t: any) => [
    {
      title: _t("general"),
      tileClass: "general",
      selected: (data?.current_category === 'general' ? "selected" : ""),
      requestParam: "general",
    },
    {
      title: _t("financial"),
      tileClass: "financial",
      selected: (data?.current_category === 'finance' ? "selected" : ""),
      requestParam: "finance",
    },
    {
      title: _t("technical"),
      tileClass: "technical",
      selected: (data?.current_category === 'technical' ? "selected" : ""),
      requestParam: "technical",
    },
  ];
  const tilesTranslated = tiles(_t);

  return (
    <section className="faq">
      <h2>{_t('FAQ')}</h2>
      <div className="faq__tile-cont">
        {tilesTranslated.map((item) => (
          <FaqTitle
            key={item.tileClass}
            data={data}
            requestParam={item.requestParam}
            loading={loading}
            title={item.title}
            setFilter={setFilter}
            tileClass={item.tileClass}
            selected={item.selected}
          />
        ))}
      </div>
      <Faqs data={data} loading={loading}/>
    </section>
  );
}
