import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import Commissions from "../components/Commissions";

const Commission = () => {

    return (
        <LandingTemplate wrapClass={"commissions__wrap"}>
            <Commissions />
        </LandingTemplate>
    );
};

export default Commission;
