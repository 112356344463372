import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import { useTestimonials } from "../apiHooks";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { _t } from "../../Common/components/InjectIntlContext";
import { Spin } from "antd";
import { Testimonial } from "../components/Testimonial";
import classnames from "classnames";

const Testimonials = () => {
  const { data, isLoading } = useTestimonials({ language: getLocale() });

  return (
    <LandingTemplate wrapClass={"default__bg-layer"}>
      <h3>{_t("testimonials")}</h3>
      <Spin spinning={isLoading}>
        <div className={classnames("testimonials")}>
          {data?.testimonials.map((testimonial) => (
            <Testimonial key={testimonial.id} testimonial={testimonial} />
          ))}
        </div>
      </Spin>
    </LandingTemplate>
  );
};

export default Testimonials;
