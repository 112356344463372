const userInfoRules = {
  first_name: [
    {
      required: false,
      message: "Please input your First Name!",
    },
  ],
  last_name: [
    {
      required: false,
      message: "Please input your Last Name!",
    },
  ],
  phone: [
    {
      required: false,
      message: "Please input your Phone!",
    },
  ],
};

const changePasswordRules = {
  new_password: [
    {
      required: true,
      message: "Please input your New Password!",
    },
  ],
  confirm_password: [
    {
      required: true,
      message: "Please input your Confirm Password!",
    },
  ],
  current_password: [
    {
      required: true,
      message: "Please input your Current Password!",
    },
  ],
};

export { userInfoRules, changePasswordRules };

export const UserPostbackOptionsQuery = "user-postback-options";

export type updatePostbackOptionsRequest = {
  postback_register: string;
  postback_deposit: string;
  postback_first_deposit: string;
  postback_qualification: string;
  postback_install: string;
};

export interface PostbackOptionsResponse {
  data: {
    status: string;
    message?: string;
    settings: {
      postback_register: string;
      postback_deposit: string;
      postback_first_deposit: string;
      postback_qualification: string;
      postback_install: string;
    };
  };
}
