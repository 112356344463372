import React from "react";
import { Filters } from "../../../Common/components/Filters";
import { Space, Form, Checkbox } from "antd";
import { DatePicker } from "../../../Common/components/DatePicker";
import { useFilters } from "../../hooks";
import { useForm } from "antd/lib/form/Form";
import { _t } from "../../../Common/components/InjectIntlContext";

export function GamersFilters({ fields = null }) {
  const [filters, updateFilters] = useFilters();
  const [form] = useForm();

  return (
    <Filters
      form={form}
      onApply={updateFilters}
      initialValues={filters}
      mainFilters={
        <Space size={22} direction="horizontal" align="center">
          <Form.Item name="date" label="Period">
            <DatePicker />
          </Form.Item>
          <Form.Item name="rDate" label="Reg">
            <DatePicker />
          </Form.Item>
          {fields}
          <Form.Item name="non_empty" valuePropName="checked">
            <Checkbox>{_t("Only with statistics")}</Checkbox>
          </Form.Item>
        </Space>
      }
    />
  );
}
