import React, { useState } from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import MainLayout from "../../Layouts/MainLayouts";
import { PageTemplate } from "../../Layouts/PageTemplate";
import { Row, Col } from "antd";
import {
  useMerge,
  useTranslationsList,
  useUpdateTranslation,
} from "../apiHooks";
import { TranslationsListWidget } from "../components/TranslationsListWidget";
import { FiltersWidget } from "../components/FiltersWidget";
import Button from "../../Common/components/Button";
import Form from "antd/lib/form";
import Btn from "../../Common/components/Button";

const UsersAdminTemplate = ({ filtersWidget, translationsList }) => {
  return (
    <>
      <Row gutter={[30, 30]}>
        <Col md={24} xl={12}>
          {filtersWidget}
        </Col>
        <Col md={24} xl={24}>
          {translationsList}
        </Col>
      </Row>
    </>
  );
};

const TranslationsAdmin = () => {
  const [filters, setFilters] = useState({
    search: "",
    empty: true,
  });
  const [page, setPage] = useState(1);
  const pageSize = 50;

  const { data, isLoading } = useTranslationsList({ ...filters, page });
  const [mergeTranslation, { isLoading: isMerging }] = useMerge();

  return (
    <MainLayout>
      <PageTemplate
        title={_t("translations")}
        actions={
          <Btn
            type="default"
            title={_t("merge")}
            disabled={isMerging}
            onClick={() => mergeTranslation()}
            loading={isMerging}
          />
        }
      >
        <UsersAdminTemplate
          filtersWidget={
            <FiltersWidget
              onSubmit={setFilters}
              initialValues={filters}
              isSubmitting={isLoading}
            />
          }
          translationsList={
            <TranslationsListWidget
              isLoading={isLoading}
              data={data}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
            />
          }
        />
      </PageTemplate>
    </MainLayout>
  );
};

export default TranslationsAdmin;
