import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {isAuthorized} from "../../../system/helpers/localstorageHelper";
import {ReactComponent as LogoImg} from "../../../images/icons/sidebar/logo.svg";
import MobileMenuImg from "../../../images/landings/mobile-menu.png";
import LockIconImg from "../../../images/landings/lock-icon.svg";
import SignUpIconImg from "../../../images/landings/sign-up-icon.png";
import {_t} from "../../Common/components/InjectIntlContext";
import {APP_ROUTES} from "../../../system/constants/constantsUrl";
import classNames from "classnames";
import LandingLanguages from "./LandingLanguages";

const Header = (props) => {
  const [modalMenu, toggleMenu] = useState(false);

  return (
    <section className="header">
      <NavLink to={'/'} className={"header__logo-link"}>
        <div className="header__logo-cont">
          <LogoImg className={'header__logo-img'}></LogoImg>
        </div>
      </NavLink>
      <div className="header__mobile-menu">
        <span>{_t('menu')}</span>
        <button className="btn btn__mobile_burger" onClick={() => toggleMenu(true)}>
          <img src={MobileMenuImg} alt="burger"/>
        </button>
      </div>

      <div className={classNames("menu-modal", modalMenu && "opened")}>
        <div className="close-modal-x" onClick={() => toggleMenu(false)}>{_t('close')}</div>
        <ul className="menu-modal__menu">
          {/*<li className="menu-item">*/}
          {/*  <NavLink to={APP_ROUTES.brands.index} className={'header__menu-link'}>{_t('Brands')}</NavLink>*/}
          {/*</li>*/}
          <li className="menu-item">
            <NavLink to={APP_ROUTES.commissions.commission} className={'header__menu-link'}>{_t('Commissions')}</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to={APP_ROUTES.faq.index} className={'header__menu-link'}>{_t('FAQ')}</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to={APP_ROUTES.contacts} className={'header__menu-link'}>{_t('Contacts')}</NavLink>
          </li>
          {
            isAuthorized() ?
              <li className="menu-item">
                <NavLink to={APP_ROUTES.dashboard.general} className={'header__menu-link'}>{_t('Dashboard')}</NavLink>
              </li>
              : null
          }
          <li className="menu-item">
            <NavLink to={"#"} className={'header__menu-link'}><LandingLanguages /></NavLink>
          </li>
        </ul>
        {
          !isAuthorized() ?
            <div className="login-area">
              <NavLink to={APP_ROUTES.login}>
                <button className="btn btn__hollow sign-in">
                  <div className="btn__content-wrap">
                    <span>{_t('sign_in')}</span>
                    {/*<img src={LockIconImg} className="header__sign-in__icon" alt=""/>*/}
                  </div>
                </button>
              </NavLink>
              <NavLink to={APP_ROUTES.registration}>
                <button className="btn btn__default sign-up">
                  <div className="btn__content-wrap">
                    <span>{_t('sign_up')}</span>
                    {/*<img src={SignUpIconImg} className="header__sign-up__icon" alt=""/>*/}
                  </div>
                </button>
              </NavLink>
            </div>
            : null
        }
      </div>

      <div className="header__desktop-menu">
        <ul className="header__menu">
          {/*<li className="header__menu-item">*/}
          {/*  <NavLink to={APP_ROUTES.brands.index} className={'header__menu-link'}>{_t('Brands')}</NavLink>*/}
          {/*</li>*/}
          <li className="header__menu-item">
            <NavLink to={APP_ROUTES.commissions.commission} className={'header__menu-link'}>{_t('Commissions')}</NavLink>
          </li>
          <li className="header__menu-item">
            <NavLink to={APP_ROUTES.faq.index} className={'header__menu-link'}>{_t('FAQ')}</NavLink>
          </li>
          <li className="header__menu-item">
            <NavLink to={APP_ROUTES.contacts} className={'header__menu-link'}>{_t('Contacts')}</NavLink>
          </li>
          {
            isAuthorized() ?
              <li className="header__menu-item">
                <NavLink to={'/dashboard'} className={'header__menu-link'}>{_t('Dashboard')}</NavLink>
              </li>
              : null
          }
          <li className="header__menu-item">
            <div className={'header__menu-link'}>
              <LandingLanguages />
            </div>
          </li>
        </ul>
        {
          !isAuthorized() ?
            <div className="login-area">
              <NavLink to={APP_ROUTES.login}>
                <button className="btn btn__hollow sign-in">
                  <div className="btn__content-wrap">
                    <span>{_t('sign_in')}</span>
                    {/*<img src={LockIconImg} className="header__sign-in__icon" alt=""/>*/}
                  </div>
                </button>
              </NavLink>
              <NavLink to={APP_ROUTES.registration}>
                <button className="btn btn__default sign-up">
                  <div className="btn__content-wrap">
                    <span>{_t('sign_up')}</span>
                    {/*<img src={SignUpIconImg} className="header__sign-up__icon" alt=""/>*/}
                  </div>
                </button>
              </NavLink>
            </div>
            : null
        }
      </div>
    </section>
  );
};

export default Header;
