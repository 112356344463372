import React, {useState} from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import Contacts from "../components/Contacts";

const ContactsPage = () => {

  return (
    <LandingTemplate wrapClass={"faq__bg-layer"}>
      <Contacts />
    </LandingTemplate>
  );
};

export default ContactsPage;
