import React from "react";
import Input from "antd/lib/input";
import Form from "antd/lib/form";
import Button from "../../Common/components/Button";
import { validationRules } from "./constants";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

const AuthForm = ({ onSubmit }) => {
  const history = useHistory();
  return  <Form name="auth_form" onFinish={onSubmit} className="auth-form">
    <Form.Item name="login" rules={validationRules.login}>
      <Input placeholder={_t("Login")} />
    </Form.Item>
    <Form.Item name="password" rules={validationRules.password}>
      <Input.Password type="password" placeholder={_t("Password")} />
    </Form.Item>
    <div className="auth-form__footer">
      <Button type="primary" title={_t("submit")} htmlType="submit" />
      <Button type="ghost" title={_t("Back")} htmlType="button" onClick={()=>history.goBack()}/>
      <NavLink to={APP_ROUTES.user.restore_password}>
        {_t("forgot_password")}
      </NavLink>
      <NavLink to={APP_ROUTES.registration}>
        {_t("dont_have_account")} {_t("sign_up")}!
      </NavLink>
    </div>
  </Form>
}

export default AuthForm;
