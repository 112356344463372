import { history } from "../store";
import { tokenTimeout, setUpAuthHandlers } from "../helpers/tokenHelper";
import { logoutHelper, loginHelper } from "../helpers/localstorageHelper";
import { refreshTokenAction } from "../../modules/Auth/actions";

setUpAuthHandlers(); //add token to request headers and handle response

export default (store) => (next) => (action) => {
  if (
    (action.payload && action.type === "AUTH_SUCCESS") ||
    action.type === "REFRESH_TOKEN_SUCCESS"
  ) {
    const data = action.payload;
    loginHelper(data);
    tokenTimeout(() => store.dispatch(refreshTokenAction()));
    if (action.type === "AUTH_SUCCESS") {
      history.push("/dashboard");
    }
  }
  if (action.type === "LOGOUT_SUCCESS") {
    logoutHelper();
  }

  return next(action);
};
