export const APP_ROUTES = {
  login: "/login",
  registration: "/registration",
  all_components: "/all-components",
  dashboard: {
    general: "/dashboard",
    commissions: "/dashboard/commissions",
    promo: "/dashboard/promo",
    trackers: "/dashboard/trackers",
    sub_id: "/dashboard/sub_id",
    click_id: "/dashboard/click_id",
    landings: "/dashboard/landings",
    gamers: "/dashboard/gamers",
    gamer_profile: "/dashboard/profile/:projectId/:gamerId",
  },
  promo: {
    create: "/promo",
    archive: "/promo/archive",
    order: "/promo/order",
  },
  trackers: "/trackers",
  payments: "/payments",
  affiliates: "/affiliates",
  costs: {
    post_back_debug: {
      deposit: "/costs/post_back_debug/deposit",
      first_deposit: "/costs/post_back_debug/first-deposit",
      register: "/costs/post_back_debug/register",
      qualification: "/costs/post_back_debug/qualification",
      install: "/costs/post_back_debug/install",
    },
  },
  manage: {
    users: "/users/admin/list",
    users_create: "/users/admin/create",
    user_profile: "/users/profile/:partnerId",
    managers: "/managers/admin/list",
  },
  brands: {
    index: "/brands",
    bettilt: "/brands/bettilt",
    bahsegel: "/brands/bahsegel",
    casinoslot: "/brands/casinoslot",
  },
  commissions: {
    commission: "/commission",
    revshare: "/commission/revshare",
    cpa: "/commission/cpa",
    subaffiliate: "/commission/subaffiliate",
  },
  faq: {
    index: "/faq",
  },
  contacts: "/contacts",
  user: {
    main: "/user",
    other: "/user/other",
    reset_password: "/reset-password",
    restore_password: "/restore-password",
  },
  terms: "/terms",
  postback_options: "/postback-options",
  testimonials: "/testimonials",
  content: {
    translations: {
      list: "/translations/admin/list",
      show: "/translations/admin/show/:keyId",
    },
  },
  news: {
    list: "/news",
    show: "/news-content/:slug",
  },
  tournaments: {
    list: "/tournaments",
    show: "/tournament/:id",
  },
  reports: {
    affiliates_report: "/reports/affiliates-report",
  },
  affiliates_programs: {
    brands: "/affiliate-programs/brands",
    brand_edit: "/affiliate-programs/brands/edit/:project_id",
    landing_edit: "/affiliate-programs/brands/landing/edit/:id",
    landing_create: "/affiliate-programs/brands/create-landing/:project_id",
    program_edit: "/affiliate-programs/program/edit/:program_id",
    program_create: "/affiliate-programs/create/:project_id",
  },
  chat: {
    inner: "/chat/dialog/:id",
    list: "/chat/list",
    create: "/chat/create",
    admin: {
      inner: "/admin/chat/dialog/:id",
      list: "/admin/chat/list",
      create: "/admin/chat/create",
    },
  },
};
