import React from "react";
import { Table } from "antd";
import { RenderSubtotalCell } from "./RenderSubtotalCell";

export const RenderSubtotal = ({ translatedColumns, subTotals }) => {
  return (
    <Table.Summary.Row className={"report-table-subtotals"}>
      {translatedColumns.map((column, key) => (
        <Table.Summary.Cell index={key} key={key}>
          {column.dataIndex in subTotals ? (
            <RenderSubtotalCell
              column={column.dataIndex}
              value={subTotals[column.dataIndex].toFixed(2)}
            />
          ) : null}
        </Table.Summary.Cell>
      ))}
    </Table.Summary.Row>
  );
};
