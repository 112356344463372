import React, { useState } from "react";
import { ReportTableWidget } from "../components/ReportTable/ReportWidget";
import { Row, Col, Select, Form, Spin, Space } from "antd";
import { GamersTableTotals } from "../components/Totals/TableTotals";
import { useFilters } from "../hooks";
import {
  useGamersReport,
  useGamersReportListFile,
  useGamersTotals,
  usePromoForSelect,
} from "../apiHooks";
import { gamersColumns } from "../constants";
import { PageTemplate } from "./PageTemplate";
import { GamersFilters } from "../components/Filters/GamersFilters";
import { PieChart } from "../components/Charts/PieChart";
import { _t } from "../../Common/components/InjectIntlContext";
import { ExcelButton } from "../components/Filters/ExcelButton";

const useGamersData = () => {
  const [promoSearch, setPromoSearch] = useState();
  const [{ date, rDate, non_empty, promo_id, page, page_size }] = useFilters();
  const chartRequestParams = {
    from: date.from,
    to: date.to,
    rfrom: rDate.from,
    rto: rDate.to,
  };
  const reqFilters = {
    from: date.from,
    to: date.to,
    rfrom: rDate.from,
    rto: rDate.to,
    promo_id,
    page,
    page_size,
    non_empty,
  };
  const chartFilters = { ...chartRequestParams };
  const { isLoading, data } = useGamersReport({
    ...reqFilters,
  });
  const { isLoading: isTotalsLoading, data: totals } = useGamersTotals(
    reqFilters
  );
  const { isLoading: isPromoLoading, data: promoData } = usePromoForSelect({
    code: promoSearch,
  });

  return {
    isLoading,
    data,
    totals,
    chartFilters,
    isTotalsLoading,
    promoSearch,
    setPromoSearch,
    isPromoLoading,
    promoData,
    reqFilters,
  };
};

export const Gamers = () => {
  const {
    data,
    isLoading,
    totals,
    chartFilters,
    isTotalsLoading,
    isPromoLoading,
    promoData,
    reqFilters,
    setPromoSearch,
    promoSearch,
  } = useGamersData();

  const [getFile, { isLoading: isFileLoading }] = useGamersReportListFile(
    reqFilters as any
  );
  const clickHandler = () => {
    getFile();
  };

  return (
    <PageTemplate>
      <GamersFilters
        fields={
          <Space>
            <Form.Item name="promo_id" label="Promo">
              <Select
                onSearch={setPromoSearch as any}
                allowClear
                showSearch
                searchValue={promoSearch}
                loading={isPromoLoading}
                filterOption={false}
                defaultValue={null}
                notFoundContent={
                  isPromoLoading ? <Spin size="small" /> : undefined
                }
                style={{ minWidth: 200 }}
              >
                <Select.Option key={"all"} value={null}>
                  {_t("All")}
                </Select.Option>
                {promoData?.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.code}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <ExcelButton
              clickHandler={clickHandler}
              isLoading={isFileLoading}
            />
          </Space>
        }
      />
      <Row gutter={[30, 30]}>
        <Col xs={24} md={24} lg={12} xl={12}>
          <PieChart
            key={1}
            filters={{ ...chartFilters, chart: "partner_income" }}
          />
        </Col>
        <Col xs={24} md={24} lg={12} xl={12}>
          <PieChart key={2} filters={{ ...chartFilters, chart: "geo" }} />
        </Col>
      </Row>
      {/* </Space> */}

      <GamersTableTotals
        totals={totals}
        isLoading={isTotalsLoading}
        totalsColumns={[
          "ltv",
          "regs",
          "first_deposits",
          "deposits",
          "net_gaming",
          "partner_income",
        ]}
      />
      <ReportTableWidget
        showSettings={true}
        data={data}
        isLoading={isLoading}
        allColumns={gamersColumns}
      />
    </PageTemplate>
  );
};
