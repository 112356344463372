import React, { useCallback } from "react";
import { Form, Row, Col, Typography, Space } from "antd";
import { Button, SimpleInput } from "../../../Common/components";
import { _t } from "../../../Common/components/InjectIntlContext";

export const ManagerForm = ({ onSubmit, initialValues, isSubmitting }) => {
  const [form] = Form.useForm();
  const onFinish = useCallback(
    async (values) => {
      const res = await onSubmit(values);
      form.resetFields();
      return res;
    },
    [form, onSubmit]
  );

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Row align="middle" className="tracker-form" gutter={5}>
        <Col flex="auto">
          <Typography.Text>{_t("managers")}</Typography.Text>
        </Col>
        <Col flex="0 0 auto">
          <Space>
            <Form.Item name="name" noStyle>
              <SimpleInput placeholder={_t("manager")} />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                loading={isSubmitting}
                type="primary"
                title={_t("create")}
                htmlType="submit"
              ></Button>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
