import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "../../modules/User/reducers";
import filters from "../../modules/Dashboard/reducer";
import costs from "../../modules/Costs/reducer";
import promo from "../../modules/Promo/reducer";
import locale from "../../modules/Locales/reducer";
import reports from "../../modules/Reports/reducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    locale,
    user,
    filters,
    costs,
    promo,
    reports,
  });

export default createRootReducer;
