import moment from "moment";
import { actionTypes } from "./actions";
import { dateKey } from "./constants";
import { Filters } from "./hooks";

const getInitialDate = () => {
  let date;

  try {
    const savedDate = JSON.parse(localStorage.getItem(dateKey));
    if (savedDate !== null) {
      date = {
        from: moment(savedDate.from),
        to: moment(savedDate.to),
      };
    } else {
      date = {
        from: moment().startOf("month"),
        to: moment(),
      };
    }
  } catch (e) {
    console.log(e);
  }

  return date;
};

const initialState: Partial<Filters> = {
  date: getInitialDate(),
  rDate: {
    from: null,
    to: null,
  } as any,
  page_size: "1000",
  page: 1,
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    case "LOGOUT_SUCCESS":
    case "LOGIN_SUCCESS":
      return {
        ...state,
        date: {
          ...state.date,
          ...getInitialDate(),
        },
        rDate: {
          ...state.rDate,
          from: null,
          to: null,
        },
      };

    default:
      return state;
  }
}
