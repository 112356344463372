import React from "react";
import { Card } from "antd";
import { SettingsForm } from "./SettingsForm";
import { ReportTable } from "./ReportTable";
import { columnOptions } from "../../constants";
import { useSettings } from "../../hooks";
import { _t } from "../../../Common/components/InjectIntlContext";

export function ReportTableWidget({
  data,
  isLoading,
  allColumns,
  showGroupBy = false,
  showSettings = true,
  visibleColumnsKey,
  setFilters,
  userStatuses,
}) {
  let { onChange, visibleColumns, settings } = useSettings({
    allColumns,
    visibleColumnsKey,
  });

  const columnOptionsTranslated = columnOptions.map((item) => {
    return {
      value: item.value,
      label: _t(item.label),
    };
  });

  return (
    <Card>
      {showSettings && (
        <SettingsForm
          onSubmit={onChange}
          initialValues={settings}
          columnsOptions={columnOptionsTranslated}
          showGroupBy={showGroupBy}
        />
      )}
      <div style={{ overflowX: "auto", marginLeft: -24, marginRight: -24 }}>
        <ReportTable
          data={data?.models?.data}
          total={data?.models?.total}
          isLoading={isLoading}
          columns={visibleColumns}
          pageSize={settings.page_size}
          tableSize={"small"}
          setFilters={setFilters}
          userStatuses={userStatuses}
        />
      </div>
    </Card>
  );
}
