import React from "react";
import LandingTemplate from "../../../Layouts/LandingTemplate";
import SubAffiliate from "../../components/SubAffiliate";

const CommissionSubAffiliate = () => {
  return (
    <LandingTemplate wrapClass={"commissions__wrap commissions__wrap--more"}>
      <SubAffiliate/>
    </LandingTemplate>
  );
};

export default CommissionSubAffiliate;
