import React from "react";
import {FaqResponse} from "../../api";
import TileImg from "../../../../images/landings/faq/tile-img.svg";
import Benefits1Img from "../../../../images/landings/slider_images/benefits_1.png";

type FaqTitlesProps = {
  data: FaqResponse;
  loading: boolean;
  title: string;
  tileClass: string,
  selected: string;
  requestParam: string,
  setFilter: (v) => void
}

export function FaqTitle({title, tileClass, selected, requestParam, setFilter}: FaqTitlesProps) {
  return (
    <div onClick={() => setFilter(requestParam)} className={"tile " + tileClass + " " + selected}>
      <div className="tile__img-cont">
        <img src={TileImg} srcSet={TileImg + ' 45w, ' + Benefits1Img + ' 105w'}
             sizes="(min-width: 1440px) 105px, 45px" alt="tile img"/>
      </div>
      <h4>{title}</h4>
    </div>
  );
}
