import React from "react";
import Commission3Img from "../../../images/landings/slider_images/commissions_3.png";
import LargeSliderIcon3Img from "../../../images/landings/commissions_tab/large-slider-icon3.svg";
import { _t } from "../../Common/components/InjectIntlContext";
import CommissionTemplate from "./CommissionTemplate";

const points = (_t: any) => [
  {
    text: _t("subaffiliate_1"),
  },
  // {
  //   text: _t("subaffiliate_2"),
  // },
  // {
  //   text: _t("subaffiliate_3"),
  // },
  // {
  //   text: _t("subaffiliate_4"),
  // },
];

const SubAffiliate = () => {
  const translatedPoints = points(_t);
  const title = _t("subaffiliate_title");

  return (
    <CommissionTemplate
      title={title}
      image={Commission3Img}
      largeSlide={LargeSliderIcon3Img}
      points={translatedPoints}
    />
  );
};

export default SubAffiliate;
