import { CurrencyLabel } from "../Common/components";
import React from "react";
import { Tag } from "antd";
import { ColumnType } from "antd/lib/table";

export const dateKey = "filters.date.costs";
const statusColors = {
  success: "#27ae60",
  error: "#e42320",
};

const renderCode = (value) => {
  return (
    <pre style={{ overflowX: "auto", maxWidth: 200, marginBottom: 0 }}>
      {value}
    </pre>
  );
};

export const columnOptions: ColumnType<any>[] = [
  {
    title: "created",
    dataIndex: "postback_date",
    key: "postback_date",
  },
  {
    title: "status",
    dataIndex: "postback_status",
    key: "postback_status",
    render: (val) => {
      const status = val ? "success" : "error";
      return <Tag color={statusColors[status]}>{status}</Tag>;
    },
  },
  {
    title: "amount",
    dataIndex: "amount",
    key: "amount",
    render: (val) => <CurrencyLabel value={val} />,
  },
  {
    title: "ref_code",
    dataIndex: "refcode",
    key: "refcode",
  },
  {
    title: "gamer",
    dataIndex: "gamer_id",
    key: "gamer_id",
  },
  {
    title: "url",
    dataIndex: "url",
    key: "url",
    render: renderCode,
  },
  {
    title: "info",
    dataIndex: "info",
    key: "info",
    render: renderCode,
  },
  {
    title: "project",
    dataIndex: "project_name",
    key: "project_name",
  },
];

const attemptColumn = {
  title: "attempts",
  dataIndex: "attempts",
  key: "attempts",
};

const regDateColumn = {
  title: "reg_date",
  dataIndex: "reg_date",
  key: "reg_date",
};

const depositsColumns = [...columnOptions];
const firstDepositColumns = [...columnOptions];
firstDepositColumns.splice(2, 0, attemptColumn);
firstDepositColumns.splice(5, 0, regDateColumn);
firstDepositColumns.splice(3, 1);

const registerColumns = [...columnOptions];
registerColumns.splice(2, 0, attemptColumn);
registerColumns.splice(5, 0, attemptColumn);
registerColumns.splice(2, 1);

const qualificationColumns = [...columnOptions];
qualificationColumns.splice(2, 0, attemptColumn);
qualificationColumns.splice(5, 0, attemptColumn);
qualificationColumns.splice(2, 1);

const installColumns = [...columnOptions];
installColumns.splice(4, 1);
installColumns.splice(2, 1);

export const pagesColumn = {
  deposit: depositsColumns,
  "first-deposit": firstDepositColumns,
  register: registerColumns,
  qualification: qualificationColumns,
  install: installColumns,
};
