import { dateKey } from "./constants";

export const actionTypes = {
  UPDATE_FILTERS: "reports/affiliates-report/update-filters",
};

export const updateAffiliatesReportFilters = (filters) => {
  if (filters.date) {
    localStorage.setItem(dateKey, JSON.stringify(filters.date));
  }
  return {
    type: actionTypes.UPDATE_FILTERS,
    payload: {
      ...filters,
    },
  };
};
