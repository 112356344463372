import React from "react";
import BackBtnImg from "../../../images/landings/commissions_tab/back-btn-icon.svg";
import {_t} from "../../Common/components/InjectIntlContext";
import CommissionSlide from "./CommissionSlide";
import {useHistory} from "react-router-dom";

const CommissionTemplate = ({title, image, largeSlide, points}) => {
  const history = useHistory();

  return (
    <section className="commissions">
      <div className="commissions__header-cont">
        <div className="commissions__header">
          <h2>{title}</h2>
        </div>
        <button className="btn btn__hollow back-btn" onClick={history.goBack} tabIndex={0}>
          <img src={BackBtnImg} alt=""/>
          <span>{_t('back')}</span>
        </button>
      </div>
      <div className="slider__item" style={{display: "none"}}></div>
      <CommissionSlide title={title} image={image}
                       largeSlide={largeSlide} points={points}/>
    </section>
  );
};

export default CommissionTemplate;
