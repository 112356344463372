import { useSelector, useDispatch } from "react-redux";
import { updateAffiliatesReportFilters } from "./actions";
import {
  affiliateReportColumns,
  columnOptions,
  subtotalColumns,
} from "./constants";
import { ColumnType } from "antd/lib/table";
import { getItem, setItem } from "../../system/helpers/localstorageHelper";
import { useMemo, useState } from "react";
import {
  useAffiliatesReport,
  useAffiliatesReportUserStatuses,
} from "./apiHooks";
import moment from "moment";

export type Filters = {
  date: {
    from: string;
    to: string;
  };
  rDate: {
    from: string;
    to: string;
  };
  page: number;
  page_size: string;
  currentPage: string;
  login: string;
  balance: string;
  sort: string;
  status: string;
  role: string;
  traffic_type: string;
  cash_type: string;
  hits: string;
  hosts: string;
  regs: string;
  rounds: string;
  qualified_players: string;
  first_deposits: string;
  deposits: string;
  withdraws: string;
  rd: number;
  romi: number;
  multiacc_count: number;
  deposits_count: number;
  ng: number;
  comppoints: string;
  casino_profit: string;
  partner_income: string;
  payouts: string;
  programs_filter: string;
  program_types_filter: string;
};

export const useFilters = () => {
  const filters = useSelector((state: { reports }) => state.reports) as Filters;
  const dispatch = useDispatch();
  const updateFilters = (v: Partial<Filters>) =>
    dispatch(updateAffiliatesReportFilters(v));

  return [filters, updateFilters] as const;
};

export const usePaginator = () => {
  const [filters, updateFilters] = useFilters();
  const setPage = (page) => updateFilters({ page });

  return [filters.page, setPage] as const;
};

export const useSubtotals = (data, translatedColumns) => {
  const subtotalColumnsArray = translatedColumns
    .filter((x) => subtotalColumns.includes(x.dataIndex))
    .map((column) => {
      return column.dataIndex;
    });
  let subTotals = [];
  subtotalColumnsArray.forEach((column) => {
    subTotals[column] = 0;
  });
  data?.forEach((row) => {
    subtotalColumnsArray.forEach((columnName) => {
      subTotals[columnName] += row[columnName] ? parseFloat(row[columnName]) : 0;
    });
  });
  return subTotals;
};

export function useSettings<Record>({
  allColumns,
  visibleColumnsKey,
}: {
  allColumns: ColumnType<Record>[];
  visibleColumnsKey: string;
}) {
  const [filters, updateFilters] = useFilters();
  const allColumnValues = affiliateReportColumns.map(
    (column: any) => column.dataIndex
  );

  const getColumns = (columns) => {
    const columnsSet = new Set(columns);
    const columnsToOmitSet = new Set(
      allColumnValues.filter((name) => !columnsSet.has(name))
    );
    return allColumns.filter(
      (column) => !columnsToOmitSet.has(column.dataIndex)
    );
  };

  const storageVisibleColumns = getItem(visibleColumnsKey);
  const defaultColumns = columnOptions
    .filter((field) => !field.hidden)
    .map((field) => field.value);
  const visibleColumnsArray = storageVisibleColumns
    ? JSON.parse(storageVisibleColumns)
    : defaultColumns;
  const [visibleColumns, setVisibleColumns] = useState(
    getColumns(visibleColumnsArray)
  );

  const settings = useMemo(
    () => ({
      columns: visibleColumnsArray,
      page_size: filters.page_size,
    }),
    [filters.page_size]
  );

  const onChange = ({
    columns,
    page_size,
  }: {
    columns: string[];
    page_size: string;
    groupBy;
  }) => {
    setItem(visibleColumnsKey, JSON.stringify(columns));
    setVisibleColumns(getColumns(columns));
    updateFilters({ page_size });
  };

  return {
    onChange,
    visibleColumns,
    settings,
  };
}

export const useTableData = () => {
  const [filters, updateFilters] = useFilters();

  const reqFilters = {
    from: moment(filters.date.from, "YYYY-MM-DD"),
    to: moment(filters.date.to, "YYYY-MM-DD"),
    reg_date_from: filters.rDate.from
      ? moment(filters.rDate.from, "YYYY-MM-DD")
      : undefined,
    reg_date_to: filters.rDate.to
      ? moment(filters.rDate.to, "YYYY-MM-DD")
      : undefined,
    page_size: filters.page_size,
    page: filters.page,
    login: filters.login,
    balance: filters.balance,
    sort: filters.sort,
    status: filters.status,
    role: filters.role,
    traffic_type: filters.traffic_type,
    cash_type: filters.cash_type,
    hits: filters.hits,
    hosts: filters.hosts,
    regs: filters.regs,
    rounds: filters.rounds,
    qualified_players: filters.qualified_players,
    first_deposits: filters.first_deposits,
    deposits: filters.deposits,
    withdraws: filters.withdraws,
    rd: filters.rd,
    romi: filters.romi,
    deposits_count: filters.deposits_count,
    multiacc_count: filters.multiacc_count,
    ng: filters.ng,
    comppoints: filters.comppoints,
    casino_profit: filters.casino_profit,
    partner_income: filters.partner_income,
    payouts: filters.payouts,
    programs_filter: filters.programs_filter,
    program_types_filter: filters.program_types_filter,
  };

  const { isLoading, data } = useAffiliatesReport({ ...reqFilters } as any);
  const {
    isLoading: isLoadingStatuses,
    data: userStatuses,
  } = useAffiliatesReportUserStatuses();

  return {
    setFilters: updateFilters,
    filters,
    isLoading,
    data,
    reqFilters,
    userStatuses,
  };
};
