import React from "react";
import { PageTemplate } from "../../Layouts/PageTemplate";
import { _t } from "../../Common/components/InjectIntlContext";
import {
  allAffiliateReportsPageColumns,
  visibleAffiliatesReportColumnsKey,
} from "../constants";
import { ReportTableWidget } from "../components/ReportTable/ReportWidget";
import MainLayout from "../../Layouts/MainLayouts";
import { useTableData } from "../hooks";
import { AffiliatesFilters } from "../components/Filters/AffiliatesFilters";
import { useAffiliatesReportFile } from "../apiHooks";
import { ExcelButton } from "../../Dashboard/components/Filters/ExcelButton";

const AffiliatesReport: React.FC = () => {
  const {
    setFilters,
    filters,
    data,
    isLoading,
    reqFilters,
    userStatuses,
  } = useTableData();

  const [getFile, { isLoading: isFileLoading }] = useAffiliatesReportFile(
    reqFilters as any
  );
  const clickHandler = () => {
    getFile();
  };

  return (
    <MainLayout>
      <PageTemplate
        title={_t("affiliates_report")}
        additionalClass="affiliates-report"
      >
        <AffiliatesFilters
          setFilters={setFilters}
          initialValues={filters}
          excelButton={
            <ExcelButton
              clickHandler={clickHandler}
              isLoading={isFileLoading}
            />
          }
        />
        <ReportTableWidget
          data={data}
          isLoading={isLoading}
          allColumns={allAffiliateReportsPageColumns}
          visibleColumnsKey={visibleAffiliatesReportColumnsKey}
          setFilters={setFilters}
          userStatuses={userStatuses}
        />
      </PageTemplate>
    </MainLayout>
  );
};

export default AffiliatesReport;
