import React from "react";
import How1Img from "../../../images/landings/slider_images/how-it-works_1.png";
import How2Img from "../../../images/landings/slider_images/how-it-works_2.png";
import How3Img from "../../../images/landings/slider_images/how-it-works_3.png";
import How4Img from "../../../images/landings/slider_images/how-it-works_4.png";
import {_t} from "../../Common/components/InjectIntlContext";

const slides = (_t: any) => [
  {
    title: _t("how_1"),
    image: How1Img,
  },
  {
    title: _t("how_2"),
    image: How2Img,
  },
  {
    title: _t("how_3"),
    image: How3Img,
  },
  {
    title: _t("how_4"),
    image: How4Img,
  },
];

const HowItWorks = ({ get_started }) => {
  const slidesTranslated = slides(_t);

  return (
    <section className="how-it-works">
      <h2>{_t('how_it_works')}</h2>
      <div className="slider">
        {slidesTranslated.map((slide, index) => (
          <div key={index} className="slider__item">
            <div className="slider__item-content">
              <div className="slider__item__img-cont">
                <img src={slide.image} alt=""/>
              </div>
              <span>{slide.title}</span>
            </div>
          </div>
        ))}
      </div>
      <button className="btn btn__default" onClick={get_started}>{_t('get started')}</button>
    </section>
  );
};

export default HowItWorks;
