import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import TermsPage from "../components/TermsPage";

const Terms = () => {
  return (
    <LandingTemplate wrapClass={"default__bg-layer"}>
      <TermsPage />
    </LandingTemplate>
  );
};

export default Terms;
