import { queryCache, useMutation, useQuery } from "react-query";
import {
  getMergeTranslations,
  getTranslationsList,
  getTranslationsShow,
  updateTranslation,
} from "./api";
import { TranslationsListRequest, TranslationsShowRequest } from "./constants";
import { notify } from "../../system/utils/notification";

const TranslationsList = "translations-list";
const TranslationsShow = "translations-show";

export const useTranslationsList = (params: TranslationsListRequest) => {
  return useQuery([TranslationsList, params], (k, params) =>
    getTranslationsList(params)
  );
};

export const useUpdateTranslation = () => {
  return useMutation(updateTranslation, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(TranslationsShow);
      notify("success", "success");
    },
    onError: () => {
      notify("error", "error");
    },
  });
};

export const useTranslationsShow = (params: TranslationsShowRequest) => {
  return useQuery([TranslationsShow, params], (k, params) =>
    getTranslationsShow(params)
  );
};

export const useMerge = () => {
  return useMutation(getMergeTranslations, {
    onSuccess: (data, variables) => {
      notify("success", "success");
    },
    onError: () => {
      notify("error", "error");
    },
  });
};
