import React, { useEffect } from "react";
import { Spin, Input } from "antd";
import {
  useGetUserPostbackOptions,
  useUpdateUserPostbackOptions,
} from "../apiHooks";
import Form from "antd/lib/form";
import Button from "../../Common/components/Button";
import { _t } from "../../Common/components/InjectIntlContext";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 24 },
};

const PostbackOptions: React.FC = () => {
  const {
    data,
    isLoading: isLoadingPostbackOptions,
  } = useGetUserPostbackOptions();

  const [
    changePostbackOptions,
    { isLoading: isUpdating },
  ] = useUpdateUserPostbackOptions();

  const [form] = Form.useForm();

  const onSubmit = (values) => changePostbackOptions(values);

  const initialValues = {
    postback_register: data?.settings.postback_register,
    postback_deposit: data?.settings.postback_deposit,
    postback_first_deposit: data?.settings.postback_first_deposit,
    postback_qualification: data?.settings.postback_qualification,
    postback_install: data?.settings.postback_install,
  };
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Spin spinning={isLoadingPostbackOptions || isUpdating}>
      <Form
        form={form}
        {...layout}
        name="user_postback_settings_form"
        onFinish={onSubmit}
      >
        <Form.Item label={_t("postback_register")} name="postback_register">
          <Input />
        </Form.Item>
        <Form.Item label={_t("postback_deposit")} name="postback_deposit">
          <Input />
        </Form.Item>
        <Form.Item
          label={_t("postback_first_deposit")}
          name="postback_first_deposit"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={_t("postback_qualification")}
          name="postback_qualification"
        >
          <Input />
        </Form.Item>
        <Form.Item label={_t("postback_install")} name="postback_install">
          <Input />
        </Form.Item>
        <Form.Item className="text-center">
          <Button type="primary" title={_t("Apply")} htmlType="submit" />
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default PostbackOptions;
