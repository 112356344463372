import React from "react";
import { useDispatch } from "react-redux";
import AuthForm from "../components/AuthForm";
import { isAuthorized } from "../../../system/helpers/localstorageHelper";
import { Redirect } from "react-router-dom";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { useSignin } from "../hooks";
import { _t } from "../../Common/components/InjectIntlContext";
import {returnAdminHelper} from "../../../system/helpers/localstorageHelper";
import AuthTemplate from "../../Layouts/AuthTemplate";

interface LoginProps {
  accessCheck?: boolean
}

const Login: React.FC<LoginProps> = ({accessCheck}) => {
  const [useSigninHandler] = useSignin(_t);
  const dispatch = useDispatch();
  const Submit = (values) => {
    if (accessCheck) {
      returnAdminHelper()
    }
    dispatch(useSigninHandler(values));
  };

  if (isAuthorized() && !accessCheck) {
    return <Redirect to={APP_ROUTES.dashboard.general} replace />;
  }

  return (
   <AuthTemplate title={_t("sign_in")}>
     <AuthForm onSubmit={Submit} />
   </AuthTemplate>
  );
};

export default Login;
