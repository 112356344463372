import React from "react";
import Benefits1Img from "../../../images/landings/slider_images/benefits_1.png";
import Benefits2Img from "../../../images/landings/slider_images/benefits_2.png";
import Benefits3Img from "../../../images/landings/slider_images/benefits_3.png";
import { _t } from "../../Common/components/InjectIntlContext";

const slides = (_t: any) => [
  {
    title: _t("benefit_1"),
    image: Benefits1Img,
  },
  {
    title: _t("benefit_2"),
    image: Benefits2Img,
  },
  {
    title: _t("benefit_3"),
    image: Benefits3Img,
  },
];

const OurBenefits = ({ get_started }) => {
  const slidesTranslated = slides(_t);

  return (
    <section className="our-benefits" id={"our_benefits_section"}>
      <h2>{_t("our_benefits")}</h2>
      <div className="slider">
        {slidesTranslated.map((slide, index) => (
          <div key={index} className="slider__item">
            <div className="slider__item-content">
              <div className="slider__item__img-cont">
                <img src={slide.image} alt="" />
              </div>
              <span>{slide.title}</span>
            </div>
          </div>
        ))}
      </div>
      <button className="btn btn__default" onClick={get_started}>
        {_t("get started")}
      </button>
    </section>
  );
};

export default OurBenefits;
