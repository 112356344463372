export const ROLES = {
  admin: "admin",
  super: "super",
  media_buyer: "media_buyer",
  media_buyer_manager: "media_buyer_manager",
  media_buyer_partner: "media_buyer_partner",
  project_owner: "project_owner",
  user: "user",
  cpa_network: "cpa_network",
  partner: "partner",
  manager: "manager",
  tech_account: "tech_account",
  landing_master: "landing_master",
  regional_admin: "regional_admin",
  analyst: "analyst",
};

export const defaultRedirect = "/login";

export const permissions = {
  dashboard: {
    header: {
      general: [ROLES.admin, ROLES.super],
      commisions: [ROLES.admin, ROLES.super],
      costs: [
        ROLES.admin,
        ROLES.super,
        ROLES.media_buyer,
        ROLES.media_buyer_manager,
        ROLES.media_buyer_partner,
      ],
    },
  },
  manage: {
    users: [ROLES.admin, ROLES.super, ROLES.project_owner],
  },
  content: {
    translation: [ROLES.admin],
  },
  reports: {
    affiliates: [ROLES.admin],
  },
  chat: {
    admin: [ROLES.admin],
  },
};
