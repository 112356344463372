import axiosClient from "../../system/helpers/axiosConfig";
import { getLocale } from "../../system/helpers/localstorageHelper";
import {
  TranslationsListRequest,
  TranslationsListResponse,
  TranslationsShowRequest,
  TranslationsShowResponse,
  TranslationsUpdateRequestData,
  TranslationsUpdateResponse,
} from "./constants";
import { messages } from "../../system/constants/constants";

export const getTranslationsList = (params: TranslationsListRequest) => {
  const locale = getLocale();
  return axiosClient
    .get<TranslationsListResponse>(`/api/translations/manage/` + locale, {
      params,
    })
    .then((r) => r.data);
};

export const updateTranslation = (data: TranslationsUpdateRequestData) => {
  return axiosClient
    .post<TranslationsUpdateResponse>(`/api/translations/save`, data)
    .then((r) => r.data);
};

export const getTranslationsShow = (params: TranslationsShowRequest) => {
  return axiosClient
    .get<TranslationsShowResponse>(`/api/translations/show`, {
      params,
    })
    .then((r) => r.data);
};

export const getMergeTranslations = () => {
  return axiosClient
    .post("/api/translations/merge", { translations: messages })
    .then((res) => {
      console.log(res);
      return res.data;
    });
};
