import axiosClient from "../../system/helpers/axiosConfig";
import {
  AffiliatesReportRequest,
  AffiliatesReportResponse,
  AffiliatesReportUserStatusesResponse,
  ReportProgramsResponse,
} from "./constants";
import { responseFile } from "../../system/helpers/requestHelper";

export const getAffiliatesReport = (params: AffiliatesReportRequest) => {
  return axiosClient
    .post<AffiliatesReportResponse>(
      `api/admin/reports/affiliates-report`,
      params
    )
    .then((r) => r.data);
};

export const getAffiliatesReportFile = (params: AffiliatesReportRequest) => {
  return axiosClient
    .post(`api/admin/reports/affiliates-report`, params, {
      responseType: "blob",
    })
    .then((r) => {
      return responseFile(
        r,
        "affiliates_report_" + params.from + "_" + params.to + ".xlsx"
      );
    });
};

export const getAffiliatesReportUserStatuses = (params: any) => {
  return axiosClient
    .get<AffiliatesReportUserStatusesResponse>(
      `api/admin/reports/filters/statuses`,
      { params }
    )
    .then((r) => r.data);
};

export const getReportPrograms = (params: any) => {
  return axiosClient
    .get<ReportProgramsResponse>("/api/admin/reports/filters/programs", {
      params,
    })
    .then((r) => r.data);
};
