import React from "react";
import { useParams } from "react-router-dom";
import MainLayout from "../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../modules/Layouts/PageTemplate";
import { usePostLandingPageCreate } from "../hooks/";
import AffiliateLandingPageForm from "./components/AffiliateLandingPageForm";

const AffiliateLanding = () => {
  const { project_id } = useParams();
  const [createLanding] = usePostLandingPageCreate();

  return (
    <MainLayout>
      <PageTemplate title={`Create Landing page`}>
        <AffiliateLandingPageForm
          updateLanding={createLanding}
          project_id={project_id}
          create
        />
      </PageTemplate>
    </MainLayout>
  );
};

export default AffiliateLanding;
