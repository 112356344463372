import React from "react";
import LandingTemplate from "../../../Layouts/LandingTemplate";
import Revshare from "../../components/Revshare";

const CommissionRevshare = () => {
  return (
    <LandingTemplate wrapClass={"commissions__wrap commissions__wrap--more"}>
      <Revshare/>
    </LandingTemplate>
  );
};

export default CommissionRevshare;
