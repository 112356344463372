import React from "react";
import { Card, Row, Col, Spin, List, Typography } from "antd";
import { lineOptions, isCurrencyField } from "../../constants";
import { useFilters } from "../../hooks";
import { CurrencyLabel } from "../../../Common/components";
import { _t } from "../../../Common/components/InjectIntlContext";

const CardItem = ({ label, children }) => (
  <List.Item style={{ background: "white", height: "100%" }}>
    <List.Item.Meta
      style={{ textAlign: "center" }}
      title={
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          {children}
        </Typography.Title>
      }
      description={label}
    />
  </List.Item>
);

export function TableTotals({ isLoading, totals }) {
  const [{ totals: totalsColumns }] = useFilters();
  const lineOptionsTranslated = lineOptions(_t);
  return (
    <Card
      style={{ background: "#E0E8F5", padding: 0, overflow: "hidden" }}
      bodyStyle={{ padding: 0 }}
    >
      <Spin spinning={isLoading}>
        <Row gutter={[1, 1]}>
          {totals ? (
            lineOptionsTranslated
              .filter((option) => totalsColumns.includes(option.value))
              .map((column) => {
                const value = totals?.[column.value] || 0;

                return (
                  <Col xs={12} md={6} xl={4} key={column.value}>
                    <CardItem label={column.label}>
                      {isCurrencyField(column.value) ? (
                        <CurrencyLabel value={value} />
                      ) : (
                        value
                      )}
                    </CardItem>
                  </Col>
                );
              })
          ) : (
            <div style={{ minHeight: 158 }} />
          )}
        </Row>
      </Spin>
    </Card>
  );
}

export function GamersTableTotals({
  isLoading,
  totals,
  totalsColumns,
}: {
  isLoading: boolean;
  totals: any;
  totalsColumns: string[];
}) {
  return (
    <Card
      style={{ background: "#E0E8F5", padding: 0, overflow: "hidden" }}
      bodyStyle={{ padding: 0 }}
    >
      <Spin spinning={isLoading}>
        <Row gutter={[1, 1]}>
          {totals ? (
            totalsColumns.map((column) => {
              const value = totals?.[column] || 0;

              return (
                <Col xs={12} md={6} xl={4} key={column}>
                  <CardItem label={_t(column)}>
                    {isCurrencyField(column) ? (
                      <CurrencyLabel value={value} />
                    ) : (
                      value
                    )}
                  </CardItem>
                </Col>
              );
            })
          ) : (
            <div style={{ minHeight: 158 }} />
          )}
        </Row>
      </Spin>
    </Card>
  );
}
