import React, { useEffect } from "react";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "../../Common/components/Button";
import { _t } from "../../Common/components/InjectIntlContext";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { Spin } from "antd";

type TranslationFormProps = {
  isLoading: boolean;
  onFinish: any;
  keyId: number;
  translation: any;
  keyDefaultMessage: string;
};

const TranslationForm: React.FC<TranslationFormProps> = ({
  isLoading,
  onFinish,
  keyId,
  translation,
  keyDefaultMessage,
}) => {
  const [form] = Form.useForm();
  const locale = getLocale();
  const initialValues = {
    translation: translation?.translation,
    key_id: keyId,
    locale: locale,
  };
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);
  if (isLoading) {
    return (
      <Spin
        spinning={isLoading}
        style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
      ></Spin>
    );
  }
  return (
    <Form
      form={form}
      name="order_form"
      onFinish={onFinish}
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item name="key_id" hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item name="locale" hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item label={keyDefaultMessage} name="translation">
        <Input placeholder={_t("translation")} />
      </Form.Item>

      <Form.Item className="text-center">
        <Button
          type="primary"
          title={_t("Apply")}
          htmlType="submit"
          disabled={isLoading}
        />
      </Form.Item>
    </Form>
  );
};

export default TranslationForm;
