import React from "react";
import {NavLink} from "react-router-dom";
import Commission1Img from "../../../images/landings/slider_images/commissions_1.png";
import Commission2Img from "../../../images/landings/slider_images/commissions_2.png";
import Commission3Img from "../../../images/landings/slider_images/commissions_3.png";
import {APP_ROUTES} from "../../../system/constants/constantsUrl";
import {_t} from "../../Common/components/InjectIntlContext";

const commissions = (_t: any) => [
  {
    title: _t("revshare_commission"),
    image: Commission1Img,
    path: APP_ROUTES.commissions.revshare,
    description: _t('revshare_commission_desc'),
  },
  {
    title: _t("cpa_program"),
    image: Commission2Img,
    path: APP_ROUTES.commissions.cpa,
    description: _t('cpa_program_desc'),
  },
  {
    title: _t("subaffiliate_program"),
    image: Commission3Img,
    path: APP_ROUTES.commissions.subaffiliate,
    description: _t('subaffiliate_program_desc'),
  },

];

const Commissions = () => {
  const commissionsTranslated = commissions(_t);
  return (
    <section className="commissions">
      <h2>{_t('Commissions')}</h2>
      <div className="slider">
        {commissionsTranslated.map((commission, index) => (
          <div key={index} className="slider__item">
            <div className="slider__item-content">
              <div className="slider__item-content__img-plaque">
                <img src={commission.image} alt=""/>
              </div>
              <h3>{commission.title}</h3>
              <span>{commission.description}</span>
              <button className="btn btn__default" tabIndex={0}>
                <NavLink to={commission.path} tabIndex={0}>{_t('read more')}</NavLink>
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Commissions;
