import React from "react";
import { Card } from "antd";
import { Post } from "../constants";
const { Meta } = Card;

type PostPreviewProps = {
  post: Post;
};

export function PostPreview({ post }: PostPreviewProps) {
  return (
    <Card
      key={post.id}
      hoverable
      className={"news-list__post"}
      cover={
        <img
          className={"news-list__post-img"}
          alt="example"
          src={`${post.host}${post.options_parsed.image}`}
        />
      }
    >
      <Meta
        className={"news-list__post-content"}
        title={
          <>
            <div className={"news-list__post-date"}>{post.post_date}</div>
            <div>{post.caption}</div>
          </>
        }
        description={
          <div dangerouslySetInnerHTML={{ __html: post.short_text }}></div>
        }
      />
    </Card>
  );
}
