import React from "react";
import BtnDownImg from "../../../images/landings/btn-down.svg";
import { _t } from "../../Common/components/InjectIntlContext";

const Welcome = ({ get_started }) => {
  return (
    <section className="welcome">
      <h1>{_t("welcome_title")}</h1>
      <p>{_t("welcome_message")}</p>
      <button className="btn btn__default" onClick={get_started}>
        {_t("get started")}
      </button>
      <a href={"#our_benefits_section"}>
        <button className="btn btn__scroll btn__scroll--down">
          <img src={BtnDownImg} alt="down" />
        </button>
      </a>
    </section>
  );
};

export default Welcome;
