export const createOptions: any | { label: any; value: string }[] = (
  data,
  key
) => {
  return (
    data &&
    Object.keys(data).map((item) => ({
      label: key ? data[item][key] : data[item].toString(),
      value: item,
    }))
  );
};
