import React from "react";
import LandingTemplate from "../../../Layouts/LandingTemplate";
import Cpa from "../../components/Cpa";

const CommissionCpa = () => {
  return (
    <LandingTemplate wrapClass={"commissions__wrap commissions__wrap--more"}>
      <Cpa/>
    </LandingTemplate>
  );
};

export default CommissionCpa;
