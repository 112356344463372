import React from "react";
import { isCurrencyField } from "../../../Dashboard/constants";
import { CurrencyLabel } from "../../../Common/components";

export const RenderSubtotalCell = ({ column, value }) => {
  return isCurrencyField(column) ? (
    <CurrencyLabel value={value} />
  ) : (
    <>{value}</>
  );
};
