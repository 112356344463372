import React from "react";
import { Form, Row, Col, Space, Button } from "antd";
import { SelectInput } from "../../../Common/components";
import { SettingOutlined } from "@ant-design/icons";
import { CheckBoxSelect } from "../../../Common/components/CheckBoxSelect";
import { _t } from "../../../Common/components/InjectIntlContext";
import { groupOptions, perPageOptions } from "../../constants";

export const SettingsForm = ({
  onSubmit,
  initialValues,
  columnsOptions,
  showGroupBy,
}) => {
  const [form] = Form.useForm();
  const groupOptionsTranslated = groupOptions(_t);
  const perPageOptionsTranslated = perPageOptions(_t);

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <Row align="middle" gutter={5}>
        <Col flex="auto">
          {showGroupBy && (
            <Form.Item name="groupBy" style={{ width: 100 }}>
              <SelectInput
                allowClear={false}
                onDropdownVisibleChange={(open) => !open && form.submit()}
                options={groupOptionsTranslated}
              />
            </Form.Item>
          )}
        </Col>
        <Col flex="0 0 auto">
          <Space>
            <Form.Item name="page_size" label={_t("show_per_page")}>
              <SelectInput
                allowClear={false}
                onDropdownVisibleChange={(open) => !open && form.submit()}
                options={perPageOptionsTranslated}
              />
            </Form.Item>
            <Form.Item name="columns">
              <CheckBoxSelect
                onDropdownVisibleChange={(open) => {
                  !open && form.submit();
                }}
                options={columnsOptions}
              >
                <Button
                  style={{ borderRadius: 30 }}
                  size="large"
                  icon={<SettingOutlined style={{ lineHeight: 0 }} />}
                >
                  {_t("settings")}
                </Button>
              </CheckBoxSelect>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
