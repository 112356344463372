import { createManager, getManagersList } from "./api";
import { useQuery, useMutation, queryCache } from "react-query";
import { notify } from "../../system/utils/notification";
import { GetManagersRequest } from "./constants";
import { _t } from "../Common/components/InjectIntlContext";

const ManagersList = "managers-admin";

export const useManagersList = (params: GetManagersRequest) => {
  return useQuery([ManagersList, params], (k, params) =>
    getManagersList(params)
  );
};

export const useManagersCreate = () => {
  const created = _t("created");
  return useMutation(
    ({ name }: { name: string }) => createManager({ name, active: 1 }),
    {
      onSuccess: (data) => {
        notify("success", created);
        queryCache.invalidateQueries(ManagersList);
      },
      onError: (error: any) => {
        notify("error", "Error");
      },
      throwOnError: true,
    }
  );
};
