import React from "react";
import Commission2Img from "../../../images/landings/slider_images/commissions_2.png";
import LargeSliderIcon2Img from "../../../images/landings/commissions_tab/large-slider-icon2.svg";
import {_t} from "../../Common/components/InjectIntlContext";
import CommissionTemplate from "./CommissionTemplate";

const points = (_t: any) => [
  {
    text: _t("cpa_1"),
  },
  {
    text: _t("cpa_2"),
  },
  {
    text: _t("cpa_3"),
  },
  {
    text: _t("cpa_4"),
  },
  {
    text: _t("cpa_5"),
  },
  {
    text: _t("cpa_6"),
  },
  {
    text: _t("cpa_7"),
  },
];

const Cpa = () => {
  const translatedPoints = points(_t);
  const title = _t("cpa_title");
  return (
    <CommissionTemplate title={title} image={Commission2Img}
                        largeSlide={LargeSliderIcon2Img} points={translatedPoints}/>
  );
};

export default Cpa;
