import React, { useEffect } from "react";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { Route } from "react-router-dom";
import { General } from "../pages/General";
import { Commissions } from "../pages/Commissions";
import { Promos } from "../pages/Promos";
// import { Trackers } from "../pages/Trackers";
import { Landings } from "../pages/Landings";
import { SubId } from "../pages/SubID";

import MainLayout from "../../Layouts/MainLayouts";
import { statAction } from "../../Auth/actions";
import { useDispatch } from "react-redux";
import { Gamers } from "../pages/Gamers";
import { ClickId } from "../pages/ClickID";
import { Typography } from "antd";
import { GamerProfile } from "../pages/GamerProfile";
import { _t } from "../../Common/components/InjectIntlContext";

const links = (_t: any) => [
  {
    title: _t("General"),
    path: APP_ROUTES.dashboard.general,
    exact: true,
  },
  {
    title: _t("Commissions"),
    path: APP_ROUTES.dashboard.commissions,
  },
  {
    title: _t("Promo"),
    path: APP_ROUTES.dashboard.promo,
  },
  {
    title: _t("Sub Id"),
    path: APP_ROUTES.dashboard.sub_id,
  },
  {
    title: _t("Click Id"),
    path: APP_ROUTES.dashboard.click_id,
  },
  {
    title: _t("Landings"),
    path: APP_ROUTES.dashboard.landings,
  },
  {
    title: _t("Gamers"),
    path: APP_ROUTES.dashboard.gamers,
  },
];

export default function Dashboard() {
  const translatedLinks = links(_t);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(statAction);
  }, []);

  return (
    <MainLayout headerLinks={translatedLinks}>
      <Route
        render={(props) => {
          const item = translatedLinks.find(
            (l) => l.path === props.location.pathname
          );
          return item ? (
            <Typography.Title style={{ marginBottom: 20 }} level={4}>
              {item.title}
            </Typography.Title>
          ) : null;
        }}
      ></Route>
      <Route exact path={APP_ROUTES.dashboard.general} component={General} />
      <Route path={APP_ROUTES.dashboard.commissions} component={Commissions} />
      {/* </RolesWrapper> */}
      <Route path={APP_ROUTES.dashboard.promo} component={Promos} />
      {/*<Route path={APP_ROUTES.dashboard.trackers} component={Trackers} />*/}
      <Route path={APP_ROUTES.dashboard.sub_id} component={SubId} />
      <Route path={APP_ROUTES.dashboard.click_id} component={ClickId} />
      <Route path={APP_ROUTES.dashboard.landings} component={Landings} />
      <Route path={APP_ROUTES.dashboard.gamers} component={Gamers} />
      <Route
        path={APP_ROUTES.dashboard.gamer_profile}
        component={GamerProfile}
      />
    </MainLayout>
  );
}
