import axiosClient from "../../system/helpers/axiosConfig";
import {
  CreateManagerRequest,
  CreateManagerResponse,
  GetManagersRequest,
  ManagersListResponse,
} from "./constants";

export const getManagersList = (params: GetManagersRequest) => {
  return axiosClient
    .get<ManagersListResponse>(`/api/admin/managers`, { params })
    .then((r) => r.data);
};

export const createManager = (params: CreateManagerRequest) => {
  return axiosClient
    .post<CreateManagerResponse>(`/api/admin/managers`, params)
    .then((r) => r.data);
};
