import React from "react";
import Commission1Img from "../../../images/landings/slider_images/commissions_1.png";
import LargeSliderIcon1Img from "../../../images/landings/commissions_tab/large-slider-icon1.svg";
import { _t } from "../../Common/components/InjectIntlContext";
import CommissionTemplate from "./CommissionTemplate";

const points = (_t: any) => [
  {
    text: _t("revshare_1"),
  },
  {
    text: _t("revshare_2"),
  },
  {
    text: _t("revshare_3"),
  },
  {
    text: _t("revshare_4"),
  },
  {
    text: _t("revshare_5"),
  },
];

const Revshare = () => {
  const translatedPoints = points(_t);
  const title = _t("revshare_title");

  return (
    <CommissionTemplate
      title={title}
      image={Commission1Img}
      largeSlide={LargeSliderIcon1Img}
      points={translatedPoints}
    />
  );
};

export default Revshare;
