import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import { PageTemplate } from "../../Layouts/PageTemplate";
import { Card, Row } from "antd";
import TranslationForm from "../components/TranslationForm";
import { useTranslationsShow, useUpdateTranslation } from "../apiHooks";
import MainLayout from "../../Layouts/MainLayouts";
import Col from "antd/lib/col";
import { useParams } from "react-router-dom";
import { getLocale } from "../../../system/helpers/localstorageHelper";

const TranslationsEdit = () => {
  const [updateTranslation, { isLoading }] = useUpdateTranslation();
  const { keyId } = useParams();
  const locale = getLocale();

  const { data, isLoading: isLoadingTranslation } = useTranslationsShow({
    key_id: keyId,
    locale: locale,
  });

  return (
    <MainLayout>
      <PageTemplate title={_t("translations")}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Card title={""}>
              <TranslationForm
                isLoading={isLoading || isLoadingTranslation}
                onFinish={updateTranslation}
                keyId={keyId}
                translation={data?.translation}
                keyDefaultMessage={data?.key_default_message}
              />
            </Card>
          </Col>
        </Row>
      </PageTemplate>
    </MainLayout>
  );
};

export default TranslationsEdit;
