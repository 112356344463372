import { useMutation, useQuery } from "react-query";
import { removeEmpty } from "../Dashboard/apiHooks";
import {
  AffiliatesReportProgramsQuery,
  AffiliatesReportQuery,
  AffiliatesReportRequest,
  AffiliatesReportUserStatusesQuery,
} from "./constants";
import {
  getAffiliatesReport,
  getAffiliatesReportFile,
  getAffiliatesReportUserStatuses,
  getReportPrograms,
} from "./api";

export const useAffiliatesReport = (params: AffiliatesReportRequest) => {
  params = removeEmpty(params);

  return useQuery([AffiliatesReportQuery, params], (k, params) =>
    getAffiliatesReport({
      ...params,
    })
  );
};

export const useAffiliatesReportFile = (params: AffiliatesReportRequest) => {
  params = removeEmpty(params);
  return useMutation(() =>
    getAffiliatesReportFile({
      export: "xls",
      ...params,
    })
  );
};

export const useAffiliatesReportUserStatuses = (params = {}) => {
  return useQuery([AffiliatesReportUserStatusesQuery, params], (k, params) =>
    getAffiliatesReportUserStatuses(params)
  );
};

export const useReportPrograms = (params = {}) => {
  return useQuery([AffiliatesReportProgramsQuery, params], (k, params) =>
    getReportPrograms(params)
  );
};
