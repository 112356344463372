import React from "react";
import enFlag from "../../images/icons/flags/en.png";
import deFlag from "../../images/icons/flags/de.png";
import esFlag from "../../images/icons/flags/es.png";
import jaFlag from "../../images/icons/flags/ja.png";
import noFlag from "../../images/icons/flags/no.png";
import ptFlag from "../../images/icons/flags/pt.png";
import svFlag from "../../images/icons/flags/sv.png";
import trFlag from "../../images/icons/flags/tr.png";
import ruFlag from "../../images/icons/flags/ru.png";

export const DEFAULT_LOCALE = "en";
export const LOCALE_KEY = "locale";

export type translationData = {
  [key: string]: {
    [key: string]: string;
  };
};

export type translationResponse = {
  translations: translationData;
};

export type Language = {
  id: number;
  code: string;
  name: string;
};

export type LanguagesResponse = {
  languages: Language[];
  status: string;
};

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const flags = {
  en: enFlag,
  de: deFlag,
  es: esFlag,
  ja: jaFlag,
  no: noFlag,
  pt: ptFlag,
  sv: svFlag,
  tr: trFlag,
  ru: ruFlag,
};
