import React, { useEffect } from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import Welcome from "../../Landing/components/Welcome";
import OurBenefits from "../../Landing/components/OurBenefits";
import HowItWorks from "../../Landing/components/HowItWorks";
import Commissions from "../../Landing/components/Commissions";
import Portfolio from "../../Landing/components/Portfolio";
import {
  isAuthorized,
  setItem,
} from "../../../system/helpers/localstorageHelper";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { history } from "../../../system/store";
import { INVITATION_CODE } from "../constants";

const Landing = () => {
  let invitationCode = new URL(window.location.href).searchParams.get(
    INVITATION_CODE
  );
  useEffect(() => {
    if (invitationCode) {
      setItem(INVITATION_CODE, invitationCode);
    }
  }, [invitationCode]);

  const onGetStarted = (values) => {
    history.push(
      isAuthorized() ? APP_ROUTES.dashboard.general : APP_ROUTES.registration
    );
  };

  return (
    <LandingTemplate>
      <Welcome get_started={onGetStarted} />
      <OurBenefits get_started={onGetStarted} />
      <HowItWorks get_started={onGetStarted} />
      <Commissions />
      {/*<Portfolio />*/}
    </LandingTemplate>
  );
};

export default Landing;
