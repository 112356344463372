import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import { useNewsList } from "../apiHooks";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { _t } from "../../Common/components/InjectIntlContext";
import { Spin } from "antd";
import { PostPreview } from "../components/PostPreview";
import { NavLink } from "react-router-dom";

const NewsList = () => {
  const locale = getLocale();
  const { data, isLoading } = useNewsList({
    language: locale,
  });
  return (
    <LandingTemplate wrapClass={"default__bg-layer"}>
      <section className="news-list">
        <h2>{_t("news")}</h2>
        <br />
        <Spin spinning={isLoading}>
          {data?.news?.map((post) => (
            <NavLink to={`/news-content/${post.url}`} key={post.id}>
              <PostPreview post={post} />
            </NavLink>
          ))}
        </Spin>
      </section>
    </LandingTemplate>
  );
};

export default NewsList;
