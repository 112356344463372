import React from "react";

const CommissionSlide = ({title, image, largeSlide, points}) => {

  return (
    <div className="slider__item">
      <div className="slider__item-content">
        <div className="slider__item-content__img-plaque">
          <img src={image} alt=""/>
        </div>
        <div className="slider__item-content__img">
          <img src={largeSlide} alt=""/>
        </div>
        <h3>{title}</h3>
        <ul>
          {points.map((point, index) => (
            <li key={index}>{point.text}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CommissionSlide;
