import React from "react";
import { Filters } from "../../../Common/components/Filters";
import { Form, TreeSelect, Row, Col } from "antd";
import { DatePicker } from "../../../Common/components/DatePicker";
import { useForm } from "antd/lib/form/Form";
import { _t } from "../../../Common/components/InjectIntlContext";
import { useReportPrograms } from "../../apiHooks";

export function AffiliatesFilters({ setFilters, initialValues, excelButton }) {
  const [form] = useForm();
  const { data, isSuccess } = useReportPrograms({});
  const programsAvailableOptions = isSuccess
    ? Object.keys(data.programs).map((project) => {
        return {
          label: project,
          value: project,
          children: Object.keys(data.programs[project]).map((program) => ({
            label: data.programs[project][program].full_name,
            value: data.programs[project][program].program_id,
          })),
        };
      })
    : undefined;
  const programTypesOptions = isSuccess
    ? Object.keys(data.program_types).map((type) => {
        return {
          label: data.program_types[type],
          value: type,
        };
      })
    : undefined;

  return (
    <Filters
      form={form}
      onApply={setFilters}
      initialValues={initialValues}
      mainFilters={
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item name="date">
              <DatePicker />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item name="rDate" label={_t("reg_date")}>
              <DatePicker />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={10}>
            <Form.Item
              label={_t("programs")}
              name="programs_filter"
              rules={[
                {
                  required: false,
                  message: _t("select_program"),
                },
              ]}
            >
              <TreeSelect
                treeData={programsAvailableOptions}
                placeholder={_t("select_program")}
                treeCheckable={true}
                showCheckedStrategy={"SHOW_CHILD"}
                maxTagCount={1}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={12}>
            <Form.Item
              label={_t("program_types")}
              name="program_types_filter"
              rules={[
                {
                  required: false,
                  message: _t("select_program"),
                },
              ]}
            >
              <TreeSelect
                treeData={programTypesOptions}
                placeholder={_t("select_program")}
                treeCheckable={true}
                showCheckedStrategy={"SHOW_CHILD"}
                maxTagCount={1}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={4} xl={2}>
            {excelButton}
          </Col>
        </Row>
      }
    />
  );
}
